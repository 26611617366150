<template>
  <div class="header-logo">
    <div class="header-logo__icon">
      <div class="header-logo__icon-circle">
        <img
          v-show="!dark"
          class="logo-img"
          :src="`${baseUrl}img/logo/logo-icon-circle-light.svg`"
          alt=""
        />
        <img
          v-show="dark"
          class="logo-img"
          :src="`${baseUrl}img/logo/logo-icon-circle-dark.svg`"
          alt=""
        />
      </div>
      <div class="header-logo__icon-content">
        <img
          v-show="!dark"
          class="logo-img"
          :src="`${baseUrl}img/logo/logo-icon-content-light.svg`"
          alt=""
        />
        <img
          v-show="dark"
          class="logo-img"
          :src="`${baseUrl}img/logo/logo-icon-content-dark.svg`"
          alt=""
        />
      </div>
    </div>
    <img
      v-show="!dark"
      class="logo-title-img"
      :src="`${baseUrl}img/logo/logo-text-light-${locale}.svg`"
      alt=""
    />
    <img
      v-show="dark"
      class="logo-title-img"
      :src="`${baseUrl}img/logo/logo-text-dark-${locale}.svg`"
      alt=""
    />
  </div>
</template>

<script>
export default {
  props: {
    locale: {
      type: String,
      required: true,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    baseUrl() {
      return process.env.BASE_URL;
    },
  },
};
</script>
<style lang="scss" scoped>
.header-logo {
  flex: 0 0 165px;
  display: flex;

  .header-logo__icon {
    width: 40px;
    position: relative;
    margin-right: 7.35px;

    .header-logo__icon-content,
    .header-logo__icon-circle {
      position: absolute;
      right: 0;
      height: 24px;
      width: 24px;
      -o-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;

      .logo-img {
        width: 24px;
        height: 24px;
      }
    }
  }

  &:hover .header-logo__icon {
    .header-logo__icon-content {
      -o-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
    .header-logo__icon-circle {
      -o-transform: scale(0, 0);
      -ms-transform: scale(0, 0);
      -moz-transform: scale(0, 0);
      -webkit-transform: scale(0, 0);
      transform: scale(0, 0);
    }
  }

  .logo-title-img {
    width: 130px;
    height: 24px;
  }
}

@media (min-width: 1024px) {
  .header-logo {
    flex: 0 0 275px;
    display: flex;

    .header-logo__icon {
      .header-logo__icon-content,
      .header-logo__icon-circle {
        right: 0;
        height: 40px;
        width: 40px;

        .logo-img {
          width: 40px;
          height: 40px;
        }
      }
    }

    .logo-title-img {
      width: 225px;
      height: 40px;
    }
  }
}
</style>
