<template>
  <div class="presentation-card">
    <div class="presentation-form-fixed">
      <div class="heading2 text-center mb-3">
        {{ catchupDialog.fields.title }}
      </div>
      <div class="body1 mb-10 col-10 pa-0 offset-1 text-center">
        {{ catchupDialog.fields.subtitle }}
      </div>
      <div class="caption textLight--text mb-4 text-center">
        {{ catchupDialog.fields.choose_title }}
      </div>
      <v-btn-toggle
        v-model="selectedFeedbackModeIndex"
        mandatory
        class="feedback-mode-selector mb-10"
        active-class="feedback-mode-selector__active"
        @change="onChangeFeedbackMode"
      >
        <v-row no-gutters class="justify-start flex-nowrap">
          <v-col
            v-for="(feedbackMode, index) in catchupDialog.children"
            :key="index"
            class="flex-grow-1"
          >
            <v-btn x-large depressed block class="feedback-mode-index-btn mb-1">
              <img
                v-show="index !== selectedFeedbackModeIndex"
                :src="feedbackMode.fields.icon"
                alt=""
              />
              <img
                v-show="index == selectedFeedbackModeIndex"
                :src="feedbackMode.fields.icon_sel"
                alt=""
              />
            </v-btn>
            <div
              v-if="feedbackMode.fields.add_info"
              class="input-label textLight--text text-center"
            >
              {{ feedbackMode.fields.add_info }}
            </div>
          </v-col>
        </v-row>
      </v-btn-toggle>
      <validation-observer ref="formPresentationValidator" tag="div">
        <v-row v-if="selectedFeedbackMode.fields.field_name">
          <v-col class="pt-0 pb-0">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              mode="eager"
              :name="selectedFeedbackMode.fields.field_name"
            >
              <v-text-field
                v-model="field_name"
                :label="selectedFeedbackMode.fields.field_name"
                maxlength="250"
                clearable
                class="pt-0 pb-0"
                clear-icon="mdi-close-circle"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row v-if="selectedFeedbackMode.fields.field_contact">
          <v-col class="pt-0 pb-0 mb-6">
            <validation-provider
              v-slot="{ errors }"
              :rules="contactValidation"
              mode="eager"
              :name="selectedFeedbackMode.fields.field_contact"
            >
              <v-text-field
                v-model="field_contact"
                :label="selectedFeedbackMode.fields.field_contact"
                maxlength="250"
                clearable
                clear-icon="mdi-close-circle"
                class="pt-0 pb-0"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pb-1">
            <v-btn
              depressed
              x-large
              color="primary"
              :disabled="!agreement || !btnEnabled"
              block
              @click="commit"
              >{{ catchupDialog.fields.button_send }}</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pt-2 d-flex pb-5 pb-sm-0">
            <v-checkbox v-model="agreement" class="mt-0 pt-0" hide-details />
            <div class="agreement-label caption-sc" @click="onToggleAgreement">
              <span class="textLight--text">{{
                catchupDialog.fields.agreement
              }}</span>
              <span>
                <router-link
                  :to="{ name: 'PrivacyPolicy' }"
                  class="caption-sc textLight--text text-decoration-underline"
                >
                  {{ catchupDialog.fields.agreement_link }}
                </router-link>
              </span>
            </div>
          </v-col>
        </v-row>
      </validation-observer>
    </div>
  </div>
</template>
<script>
import SupplementRepository from "@/api/supplement.js";

export default {
  name: "PresentationForm",
  props: {
    productIdent: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedFeedbackModeIndex: 0,
      btnEnabled: true,
      field_name: "",
      field_contact: "",
      agreement: true,
    };
  },
  computed: {
    catchupDialog() {
      return this.$store.getters.getCatchupDialogSection;
    },
    selectedFeedbackMode() {
      return (
        this.catchupDialog.children[this.selectedFeedbackModeIndex] || {
          fields: {},
        }
      );
    },
    contactValidation() {
      if (this.selectedFeedbackMode.fields.validation_contact !== "none") {
        return `required|${this.selectedFeedbackMode.fields.validation_contact}`;
      } else {
        return "required";
      }
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        if (this.$refs.formPresentationValidator)
          this.$refs.formPresentationValidator.reset();
      }
    },
  },
  methods: {
    async commit() {
      try {
        if (!(await this.$refs.formPresentationValidator.validate())) return;
        const payload = new FormData();
        payload.append("product", this.productIdent);
        payload.append("location", window.location.href);
        payload.append("contact_type", this.selectedFeedbackMode.node_name);
        payload.append("contact_value", this.field_contact);
        payload.append("name", this.field_name);
        const trace = window.b24Tracker?.guest?.getTrace() || undefined;
        if (trace) {
          payload.append("trace", trace);
        }
        this.btnEnabled = false;
        const { data } = await SupplementRepository.presentation(payload);
        if (data.code == 0) {
          if (this.$metrika) {
            console.debug('r#f.p.r');
            this.$metrika.reachGoal("form-presentation");
          }
          this.reset();
          this.$emit("commit");
        } else {
          alert(this.$t(`Common.feedback.error${data.code}`));
        }
      } finally {
        this.btnEnabled = true;
      }
    },
    reset() {
      this.selectedFeedbackModeIndex = 0;
      this.btnEnabled = true;
      this.field_name = "";
      this.field_contact = "";
      this.agreement = true;
      if (this.$refs.formPresentationValidator)
        this.$refs.formPresentationValidator.reset();
    },
    onChangeFeedbackMode() {
      this.field_contact = "";
      if (this.$refs.formPresentationValidator)
        this.$refs.formPresentationValidator.reset();
    },
    onToggleAgreement(event) {
      const tagName = event.target.tagName || "";
      if (tagName.toLowerCase() == "a") return;
      this.agreement = !this.agreement;
    },
  },
};
</script>
<style scoped lang="scss">
.presentation-form-fixed {
  height: 550px;
  max-height: 100vh;
}

.agreement-label {
  cursor: pointer;
}

.presentation-card {
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  padding: 56px 48px;
  box-shadow: 0px 0px 12px 0px rgba(48, 49, 51, 0.03),
    0px 0px 24px 0px rgba(48, 49, 51, 0.03);
  position: relative;

  @media (max-width: 640px) {
    padding: 16px 16px;
  }

  @media (max-width: 1023px) {
    border-radius: 0 !important;
  }
}

.feedback-mode-selector {
  display: block;

  ::v-deep(.col:not(:last-child) .v-btn) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  ::v-deep(.col:not(:first-child) .v-btn) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

::v-deep
  .theme--light.feedback-mode-index-btn.v-btn:not(
    .v-btn--round
  ).v-size--x-large {
  border: none;
  background-color: rgba(0, 0, 0, 0.04);

  @media (max-width: 640px) {
    padding: 0 8px;
    img {
      height: 20px;
    }
  }

  &:hover::before,
  &::before {
    opacity: 0;
  }
  .v-ripple__container {
    display: none !important;
  }
}

.feedback-mode-selector__active {
  background-color: var(--v-primary-base) !important;
  color: white !important;
}
</style>
