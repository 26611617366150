<template>
  <div class="headerContent" :class="headerClass">
    <router-link :to="'/'">
      <header-logo :locale="$i18n.locale" :dark="dark" />
    </router-link>
    <header-menu
      :menu="menu"
      :dark="dark"
      class="menuDiv"
      @on-search="doSearch"
    />
    <header-menu-options
      :menu="menu"
      :dark="dark"
      class="optionsDiv align-center"
    />
    <header-menu-mobile :menu="menu" :dark="dark" @on-search="doSearch" />
  </div>
</template>

<script>
import HeaderLogo from "./HeaderLogo.vue";
import HeaderMenu from "./HeaderMenu.vue";
import HeaderMenuMobile from "./HeaderMenuMobile.vue";
import HeaderMenuOptions from "./HeaderMenuOptions.vue";
export default {
  components: {
    HeaderLogo,
    HeaderMenu,
    HeaderMenuMobile,
    HeaderMenuOptions,
  },
  computed: {
    menu() {
      return this.$store.getters.getMenu;
    },
    headerClass() {
      return this.$route.meta?.headerClass || "";
    },
    dark() {
      return this.headerClass.indexOf("header--dark") > -1;
    },
  },
  methods: {
    doSearch(searchTerm) {
      this.$store.commit("setAppSearch", searchTerm);
      if (this.$route.name != "Search") {
        this.$nextTick(() => {
          this.$router.push({ name: "Search" });
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.optionsDiv {
  display: none;
}
.headerContent {
  padding: 14px 16px;
  height: 52px;
  box-shadow: 0px 4px 8px 0px rgba(48, 49, 51, 0.1);

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.headerContent.header--dark {
  background: var(--v-corporateGrey-base);
}
.close {
  color: var(--v-corporateGrey-base) !important;
}
.header--dark .close {
  color: white !important;
}
.header--dark .mobileMenuSubItem {
  color: white !important;
}
.header--dark .mobileMenuItem {
  color: white !important;
}
.header--dark .mobileMenu {
  background: var(--v-corporateGrey-base);
}
.menuDiv {
  flex: 1 0 0;
  justify-content: center;
  display: none;
}

@media (min-width: 1024px) {
  .headerContent {
    padding: 20px 64px;
    height: 80px;
  }
}

@media (min-width: 1280px) {
  .menuDiv {
    display: flex;
  }
  .optionsDiv {
    display: flex;
    text-align: right;
    flex: 0 0 230px;
  }
}
</style>
