<template>
  <div class="preview-block mb-2" :class="previewClass">
    <v-img
      v-if="isLoaded"
      :src="previewInfo.fields.image"
      position="center center"
      aspect-ratio="1.7788"
      class="cursor-pointer"
      @click="previewClick"
    />
    <v-skeleton-loader v-else type="image" class="mb-4" />
    <div
      v-if="isLoaded"
      class="overline-sc black-lighten2--text cursor-pointer py-2"
      @click="previewClick"
    >
      {{ humanizedDate }}
    </div>
    <v-skeleton-loader v-else type="text" />
    <div v-if="hasTags" class="mb-2 mx-n1">
      <tag-chip
        v-for="(tag, index) in previewInfo.fields.tags"
        :key="index"
        @click="$emit('tagclick', tag)"
      >
        {{ tag }}
      </tag-chip>
    </div>
    <div
      v-if="isLoaded"
      class="subtitle2 cursor-pointer pb-3"
      @click="previewClick"
    >
      {{ previewInfo.fields.title || "" }}
    </div>
    <v-skeleton-loader v-else type="text" />
    <div
      v-if="isLoaded"
      class="body1 cursor-pointer"
      @click="previewClick"
      v-html="previewInfo.fields.annotation || ''"
    />
    <v-skeleton-loader v-else type="paragraph" />
  </div>
</template>
<script>
import TagChip from "@/components/Tags/TagChip.vue";
import moment from "moment";
import "./Preview.scss";

export default {
  components: { TagChip },
  props: {
    previewInfo: {
      type: Object,
      required: true,
    },
    biggerXxl: {
      type: Boolean,
      default: false,
    },
    biggerXl: {
      type: Boolean,
      default: false,
    },
    biggerLg: {
      type: Boolean,
      default: false,
    },
    biggerMd: {
      type: Boolean,
      default: false,
    },
    biggerSm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    isLoaded() {
      return this.previewInfo.fields !== undefined;
    },
    hasTags() {
      if (!this.isLoaded) return false;
      return this.previewInfo.fields.tags.length > 0;
    },
    humanizedDate() {
      if (!this.isLoaded) return false;
      return moment(this.previewInfo.fields.date).format("D MMMM yyyy");
    },
    previewClass() {
      return {
        "bigger-xxl": this.biggerXxl,
        "bigger-xl": this.biggerXl,
        "bigger-lg": this.biggerLg,
        "bigger-md": this.biggerMd,
        "bigger-sm": this.biggerSm,
      };
    },
  },
  methods: {
    previewClick() {
      this.$emit("previewclick", this.previewInfo.id);
    },
  },
};
</script>
